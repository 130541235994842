/* Override the suggestions list container */
.pac-container {
    background-color: #fff; /* Change background color */
    border-radius: 8px;     /* Add rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    z-index: 1050;          /* Ensure it appears above other elements */
    margin-left: -30px;
}

/* Override individual suggestion items */
.pac-item {

    font-size: 14px;                 /* Change font size */
    color: #333;                     /* Change text color */
    padding: 8px 15px;              /* Add padding */
}

.pac-item:hover {
    background-color: #f0f0f0;       /* Highlight background on hover */
    cursor: pointer;
}

/* Optional: Add spacing between items */
.pac-item + .pac-item {
    border-top: 1px solid #e0e0e0;
}
